import React, { Component } from 'react';
import './style.scss';

export class VideoTutorialSlider extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section id="tutorial-slider-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="section-heading">Video <span className="color-lightgreen">Tutorial</span></h2>
                            <p className="section-paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className="col-md-8 tutorial-slider-content">
                            <div className="row">
                                <div className="col-md-6">
                                    <a href="#" className="tutorial-entry">
                                        <span>Coast to Coast</span>
                                        <img src="http://placehold.it/320x220" alt="" />
                                    </a>
                                </div>
                                <div className="col-md-6">
                                    <a href="#" className="tutorial-entry">
                                        <span>Coast to Coast</span>
                                        <img src="http://placehold.it/320x220" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}