import React, { Component } from 'react';

import './style.scss';

const slideContent = [[
    ["1"],
    ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius."],
    ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius."]
], [
    ["2"],
    ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius."],
    ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius."]
], [
    ["3"],
    ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius."],
    ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius."]
]];

export class Slider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSlideIndex: 0,
        }

        this.prevSlide = this.prevSlide.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.jumpToSlide = this.jumpToSlide.bind(this);
    }

    componentDidMount() {

    }


    prevSlide() {
        this.setState(prevState => {
            return {
                activeSlideIndex: prevState.activeSlideIndex === 0 ? slideContent.length - 1 : prevState.activeSlideIndex - 1,
            }
        })
    }

    nextSlide() {
        this.setState(prevState => {
            return {
                activeSlideIndex: prevState.activeSlideIndex === slideContent.length - 1 ? 0: prevState.activeSlideIndex + 1,
            }
        })
    }

    jumpToSlide(slideIndex) {
        this.setState({ activeSlideIndex: slideIndex });
    }

    render() {
        return (
            <div className="carousel">
                <div className="row carousel-heading">
                    <div className="col-12 col-md-8">
                        <h4 className="carousel-title">Choose Options Wash</h4>
                    </div>
                    <div className="col-4 carousel-controls">
                        <div className="carousel__navigation_button prev" onClick={this.prevSlide}></div>
                        <div className="carousel__navigation_button next" onClick={this.nextSlide}></div>
                    </div>
                </div>
                <div className="row carousel__slides_outer_wrapper">
                    <div 
                        className="carousel__slides"
                        style={{
                            'transform': `translate3d(-${this.state.activeSlideIndex * 100}%, 0px, 0px)`
                        }}
                    >
                        {slideContent.map((slide, index) => (
                            <div className="carousel__slide">
                                {slide.map(paragraph => (<p>{paragraph}</p>))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="row carousel-indicators">
                    {slideContent.map((_, index) => (
                        <span 
                            className={`carousel__indicator ${this.state.activeSlideIndex === index && "active" || ""}`}
                            onClick={() => this.jumpToSlide(index)}
                        ></span>
                    ))}
                </div>
            </div>
        );
    }
}