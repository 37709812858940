import React, { Component } from 'react';
import {BannerSection} from "../components/BannerSection/index";
import {HowItWorksSection} from "../components/HowItWorksSection/index";
import {InformationSection} from "../components/InformationSection/index";

export class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <BannerSection/>
                <HowItWorksSection/>
                <InformationSection/>
            </React.Fragment>
        );
    }
}