import React, { Component } from 'react';
import './style.scss';

export class HeaderBanner extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section id="header-banner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header-banner">
                                <div className="header-banner-image">
                                    <div className="left-image" style={{background: `url(${this.props.leftIcon})`}}>&nbsp;</div>
                                    <div className="right-image" style={{background: `url(${this.props.rightIcon})`}}>&nbsp;</div>
                                </div>
                                <div className="header-banner-content">
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-banner-section--right-image">&nbsp;</div>
                <div className="header-banner-section--left-image">&nbsp;</div>
            </section>
        );
    }
} 