import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import {SendMail} from "../components/SendMail";
import {HaveQuestionsBanner} from "../components/HaveQuestionsBanner";

export class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <HeaderBanner
                    leftIcon={'/app/assets/images/header-icons/contact-left.svg'}
                    rightIcon={'/app/assets/images/header-icons/contact-right.svg'}>
                    <h1>Contact Us</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </HeaderBanner>
                <SendMail/>
                <HaveQuestionsBanner/>
            </React.Fragment>
        );
    }
}