import '../../assets/animations/landing/image_import';
import './style.scss';

import React, { Component } from 'react';
import Lottie from 'react-lottie';

import animationData from '../../assets/animations/landing/data.json';
import BubblesLeftTop from '../../assets/images/bubbles_left_top.png';
import BubblesRight from '../../assets/images/bubbles_right.png';
import BubblesLeftBottom from '../../assets/images/bubbles_left_bottom.png';
import { Header } from '../Header';



export class BannerSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isStopped: true,
        }
    }

    render() {
        const lottieOptions = {
            loop: false,
            autoplay: true, 
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <React.Fragment>
                <Header/>

                <section id="banner-section">
                    <div className="container h-100 content">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <div className="row">
                                    <div className="col-12 col-md-8 banner-section--heading">
                                        <h1>Car <span className="color-lightgreen">washes</span></h1>
                                        <h2>In your mobile</h2>
                                    </div>
                                    <div className="col-4 language-picker-wrapper">
                                        <span className="language-picker">
                                            English
                                        </span>
                                    </div>
                                </div>
                                <div className="row banner-section--description">
                                    <div className="col-12">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.</p>
                                        <p>In sed mauris sed elit pulvinar fringilla. Nunc tempus felis nec nunc elementum sollicitudin. Mauris eget facilisis neque, sit amet accumsan eros. Sed mattis viverra elit sed viverra. Suspendisse tincidunt diam a viverra lobortis. </p>
                                    </div>
                                </div>
                                <div className="position-relative margin-top-10">
                                    <div className="row header-download-buttons">
                                        <div className="col-12 col-md-3">
                                            <h4 className="header-download-heading">Download the app now.</h4>
                                        </div>
                                        <div className="col-12 col-md-9">
                                            <div className="button__download-wrapper">
                                                <a href="#" className="button__download ios">
                                                    <span className="button-text">
                                                        <span className="subtitle">Download on the</span> 
                                                        <span className="title">App Store</span>
                                                    </span>
                                                    <span className="bubbles-top"></span>
                                                    <span className="bubbles-bottom"></span>
                                                </a>
                                            </div>

                                            <div className="button__download-wrapper">
                                                <a href="#" className="button__download android">
                                                    <span className="button-text">
                                                        <span className="subtitle">Get it on</span>
                                                        <span className="title">Play market</span>
                                                    </span>
                                                    <span className="bubbles-right"></span>
                                                    <span className="bubbles-left"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="banner-section-right-image-wrapper">
                                    <Lottie 
                                        options={lottieOptions}
                                        isStopped={this.state.isStopped}
                                        eventListeners={[
                                            {
                                              eventName: 'loaded_images',
                                              callback: () => this.setState({ isStopped: false }),
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bubbles-overlay-wrapper">
                        <div className="bubbles-left-top">
                            <img src={BubblesLeftTop}/>
                        </div>
                        <div className="bubbles-left-bottom">
                            <img src={BubblesLeftBottom}/>
                        </div>
                        <div className="bubbles-right">
                            <img src={BubblesRight}/>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
} 