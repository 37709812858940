import '../../assets/animations/howItWorks/image_import';
import './style.scss';

import React, { Component } from 'react';
import Lottie from 'react-lottie';

import animationData from '../../assets/animations/howItWorks/data.json';
import { Slider } from '../Slider';

export class HowItWorksSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            animationStopped: true,
        }

        this.trackScrolling = this.trackScrolling.bind(this);
        this.isBottom = this.isBottom.bind(this);
    }

    componentDidMount() {
        this.trackScrolling(true);
        
        document.addEventListener('scroll', this.trackScrolling);
    }

    trackScrolling(initCheck = false) {
        if (this.isBottom(initCheck)) {
            this.setState({ animationStopped: false });
            document.removeEventListener('scroll', this.trackScrolling);
        }
    }

    isBottom(initCheck) {
        const wrappedElement = document.getElementById('how-it-works');
        const offset = initCheck ? 0 : 230;
        return (wrappedElement.getBoundingClientRect().bottom + offset) <= window.innerHeight;
    }

    render() {
        const lottieOptions = {
            loop: false,
            autoplay: false, 
            animationData: animationData,
        };

        return (
            <section id="how-it-works">
                <div className="mobile-box-outer-wrapper">
                    <div className="container content">
                        <div className="row">
                            <h2 className="section-heading">
                                How It <span className="highlight">Works</span>
                            </h2>
                        </div>
                        <div className="row">
                            <div className="section-description">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="row margin-top-4-em">
                            <div className="position-relative col-12 col-sm-12 col-md-6 col-lg-4">
                                <div className="how-it-works-banner">
                                    <Lottie 
                                        options={lottieOptions}
                                        isStopped={this.state.animationStopped}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                <Slider/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="map-background left"></div>
                <div className="map-background right"></div>
            </section>
        );
    }
} 