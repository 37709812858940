import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from '../Footer';
import { DownloadSection } from "../DownloadSection";
import { Home } from "../../routes/Home";
import { Blog } from "../../routes/Blog";
import { BlogEntry } from "../../routes/BlogEntry";
import { BecomeAWasher } from "../../routes/BecomeAWasher";
import { TermsOfUse } from "../../routes/TermsOfUse";
import { Rules } from "../../routes/Rules";
import { Faq } from "../../routes/Faq";
import { Contact } from "../../routes/Contact";

export class App extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>CarShine - App</title>
                    <meta name="description" content="CarShine - App" />
                </Helmet>

                <Router>
                    <Header/>

                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/blog" exact component={Blog} />
                        <Route path="/blog/:id" component={BlogEntry} />
                        <Route path="/contact" exact component={Contact} />
                        <Route path="/faq" exact component={Faq} />
                        <Route path="/become-a-washer" exact component={BecomeAWasher} />
                        <Route path="/terms-of-use" exact component={TermsOfUse} />
                        <Route path="/rules" exact component={Rules} />
                    </Switch>

                    <DownloadSection/>
                    <Footer/>
                </Router>
            </React.Fragment>
        );
    }
} 