import React, { Component } from 'react';
import './style.scss';

export class SendMail extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section id="send-mail">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-3 align-self-center">
                            <h2 className="section-heading">Send <span className="color-lightgreen">Mail</span></h2>
                            <p className="section-paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <hr />
                            <div className="our-email">
                                <a href="#">
                                    <span>Email us</span>
                                    Info@carshine.com
                                </a>
                            </div>
                        </div>
                        <div className="col-md-8 offset-md-1 contact-form-wrapper">
                            <form className="contact-form">
                                <div className="row">
                                    <div className="col-md-4">
                                        <input type="text" placeholder="First name" />
                                    </div>
                                    <div className="col-md-4">
                                        <input type="text" placeholder="Last name" />
                                    </div>
                                    <div className="col-md-4">
                                        <input type="email" placeholder="E-mail" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <input type="text" placeholder="Subject" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <textarea placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button className="button button-primary">Send mail</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}