import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import {VideoTutorialSlider} from "../components/VideoTutorialSlider";

export class Faq extends Component {
    render() {
        return (
            <React.Fragment>
                <HeaderBanner
                    leftIcon={'/app/assets/images/header-icons/faq-left.svg'}
                    rightIcon={'/app/assets/images/header-icons/faq-right.svg'}>
                    <h1>Faq</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </HeaderBanner>
                <VideoTutorialSlider/>
            </React.Fragment>
        );
    }
}