import React, { Component } from 'react';
import { Link } from "react-router-dom";

import logo from '../../assets/images/logo.svg';
import './style.scss';

export class Header extends Component {
    render() {
        return (
            <header id="header">
                <div className="container container-fluid">
                    <div className="display-flex--items-center">
                        <div className="logo margin-right-auto">
                            <Link to="/" className="display-inline-block"><img src={logo}/></Link>
                        </div>
                        <nav className="margin-sides-auto header-navigation-wrapper">
                            <ul className="header-navigation">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/faq">Faq</Link></li>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </nav>
                        <div className="margin-left-auto header--become-a-washer">
                            <Link to="/become-a-washer" className="header__join_button">Become a washer</Link>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
} 