import React, { Component } from 'react';

import BottomDevice from '../../assets/images/bottom_device_bottom.png';
import TopDevice from '../../assets/images/bottom_device_top.png';

import './style.scss';

export class DownloadSection extends Component {
    render() {
        return (
            <section id="download-section">
                <div className="container content">
                    <div className="row download-section__row_wrapper">
                        <div className="col-4 download-section-device-outer-wrapper">
                            <div className="download-section-device-wrapper">
                                <div className="bottom-device">
                                    <img src={BottomDevice}/>
                                </div>
                                <div className="top-device">
                                    <img src={TopDevice}/>
                                </div>

                                <div className="icon__1"></div>
                                <div className="icon__2"></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 download-section__wrapper">
                            <div className="row download-section__title">
                                <h2>Download the <span className="highlight">app now.</span></h2>
                            </div>
                            <div className="row download-section__buttons">
                                <div className="button__wrapper">
                                    <a href="#" className="button__download ios">
                                        <span className="button__text_wrapper">
                                            <span className="button__text">Download on the</span> 
                                            <span className="button__store_name">App Store</span>
                                        </span>
                                    </a>
                                </div>

                                <div className="spacer-vertical">
                                    <div className="mask"></div>
                                </div>

                                <div className="button__wrapper">
                                    <a href="#" className="button__download android">
                                        <span className="button__text_wrapper">
                                            <span className="button__text">Get it on</span>
                                            <span className="button__store_name">Play market</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className="row download-section__description">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
} 