import React, { Component } from 'react';
import './style.scss';

export class HaveQuestionsBanner extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section id="have-questions-section">
                <div className="container">
                    <div className="have-question-banner">
                        <img src="/app/assets/images/question-icon.svg" alt="" />
                        <div className="have-question-banner-text">
                            <h3>You have questions?</h3>
                            <p>We have answers</p>
                        </div>
                        <a className="button button-primary" href="#">FAQ</a>
                    </div>
                </div>
            </section>
        );
    }
}