import React, { Component } from 'react';

import DribbleLogo from '../../assets/images/dribbble_logo.png';

import './style.scss';

export class Press extends Component {
    render() {
        return (
            <div className="row margin-top-9-em press-section">
                <div className="col-6">
                    <div className="press_box">
                        <div className="press_box__inner_wrapper">
                            <h3>Press</h3>

                            <div className="press_box-description-wrapper">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.
                                </p>
                            </div>
                            
                            <span className="press_box__review-button">See more review</span>
                        </div>
                        <div className="press_box__bg_pattern"></div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="reviews-wrapper row">
                        <div className="col-6">
                            <div className="row">
                                <div className="review-box bordered">
                                    <div className="review-box-inner-wrapper">
                                        <div className="reviewer-logo">
                                            <img src={DribbleLogo}/>
                                        </div>
                                        <div className="review-title"><h3>Choose Options Wash</h3></div>
                                        <div className="review-description">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row margin-top-1-em">
                                <div className="review-box">
                                    <div className="review-box-inner-wrapper">
                                        <div className="reviewer-logo">
                                            <img src={DribbleLogo}/>
                                        </div>
                                        <div className="review-title"><h3>Choose Options Wash</h3></div>
                                        <div className="review-description">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="review-box">
                                <div className="review-box-inner-wrapper">
                                    <div className="reviewer-logo">
                                        <img src={DribbleLogo}/>
                                    </div>
                                    <div className="review-title"><h3>Choose Options Wash</h3></div>
                                    <div className="review-description">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.
                                        </p>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.
                                        </p>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
} 