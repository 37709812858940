import React, { Component } from 'react';

import logo from '../../assets/images/logo.svg';

import FacebookLogo from '../../assets/images/facebook.svg';
import InstagramLogo from '../../assets/images/instagram.svg';
import TwitterLogo from '../../assets/images/twitter.svg';

import './style.scss';

export class Footer extends Component {
    render() {
        return (
            <section id="footer">
                <div className="container content">
                    <div className="row footer__top_section">
                        <div className="col-12 col-md-3 footer__logo">
                            <img src={logo}/>
                        </div>
                        <div className="col-7 footer__address">
                            <div className="address__wrapper">
                                <span className="address__icon"></span>
                                <div className="address__text">
                                    <p>Qualirt Formations Ltd</p>
                                    <p>71-75 Shelton Street, Convent Garden</p>
                                    <p>London</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 footer__become_washer">
                            <a href="#" className="footer__become_washer__button">Become a washer</a>
                        </div>
                    </div>
                    <div className="row">
                        <hr className="footer__divider"/>
                    </div>
                    <div className="row footer_bottom_section">
                        <div className="col-12 col-md-3 footer__social_outer_wrapper">
                            <div className="row mobile__center footer__social_wrapper">
                                <ul className="footer__social">
                                    <li><a href="#"><img src={FacebookLogo}/></a></li>
                                    <li><a href="#"><img src={InstagramLogo}/></a></li>
                                    <li><a href="#"><img src={TwitterLogo}/></a></li>
                                </ul>
                            </div>
                            <div className="row mobile__center">
                                Copyright 2019 Ⓒ CarShine
                            </div>
                        </div>
                        <div className="col-12 col-md-6 footer__links">
                            <ul>
                                <li><a href="#">Our Story</a></li>
                                <li><a href="#">Our Story</a></li>
                                <li><a href="#">Our Story</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Media</a></li>
                                <li><a href="#">Media</a></li>
                                <li><a href="#">Media</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Find a City</a></li>
                                <li><a href="#">Find a City</a></li>
                                <li><a href="#">Find a City</a></li>
                            </ul>
                        </div>
                        <div className="col-12 registration__button_wrap">
                            <a href="#" className="registration__button">Become a washer</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
} 