import React, { Component } from 'react';

import { Press } from '../Press';

import WorldMap from '../../assets/images/world_map.svg';

import './style.scss';

export class InformationSection extends Component {
    render() {
        return (
            <section id="who-we-are">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2>Where We <span className="color-lightgreen">Wash</span></h2>

                            <div className="description">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum magna at purus aliquam, at commodo elit varius.</p>
                            </div>

                            <div className="current_countries__wrapper">
                                <ul className="countries__list">
                                    <li>
                                        <div className="country_list__inner_wrapper">
                                            <span className="russia-flag"></span>
                                            <span className="country_list__title">Russia</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="country_list__inner_wrapper">
                                            <span className="usa-flag"></span>
                                            <span className="country_list__title">USA</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="country_list__inner_wrapper">
                                            <span className="sweden-flag"></span>
                                            <span className="country_list__title">Sweden</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="coming_soon_countries__wrapper">
                                <h3 className="coming_soon_countries__title">Coming soon</h3>

                                <ul className="countries__list">
                                    <li>
                                        <div className="country_list__inner_wrapper">
                                            <span className="russia-flag"></span>
                                            <span className="country_list__title">Russia</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="country_list__inner_wrapper">
                                            <span className="usa-flag"></span>
                                            <span className="country_list__title">USA</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="country_list__inner_wrapper">
                                            <span className="sweden-flag"></span>
                                            <span className="country_list__title">Sweden</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-6 mobile__hide">
                            <div className="world-map-wrapper">
                                <img src={WorldMap}/>
                            </div>
                        </div>
                    </div>

                    <Press />
                </div>
            </section>
        );
    }
} 